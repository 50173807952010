* {
    margin: 0;
    padding: 0;
}

#tasks-table {
    margin-bottom: 90px;
}

.functional-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0px 10px;
    align-items: center;

    &__button {
        height: 35px;
    }

    .MuiButtonBase-root {
        margin-right: 10px;
    }

}

.table-row {
    &__title {
        display: inline-block;
        max-width: 630px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: help;
    }

    &__use-filter {
        cursor: pointer;
    }

    &__viewed-block {
        position: relative;
    }

    &__viewed-circle {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #1976d2;
        border-radius: 10px;
        top: 3px;
        left: 5px;
        cursor: pointer;
    }

    &__button {
        height: 16px;
        padding: 8px 5px !important;
        font-size: 11px !important;
        min-width: 0px !important;
        margin: 0px 2px !important;
    }

    &__status {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.scrolled {
    position: fixed;
    animation-name: fadeInUp;
    animation-duration: 1s;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0;
    z-index: 2;
}

.general-report {
    display: flex;
    flex-direction: row;
}

.status {
    border-radius: 10px;
    padding: 0px 5px;
    color: white;
}

h3 {
    margin: 10px;
}

.status.completed {
    background: #008000;
}

.status.supposedly-completed {
    background: #F2BF57;
}

.status.in-progress {
    background: #FF6600;
}

.status.scheduled {
    background: rgb(128, 128, 128);
}

.sprint-container {
    position: fixed;
    bottom: 0;
    width: calc(100% - 170px);

    &__sprints {
        display: flex;
        flex-direction: row;
        padding: 5px 10px;

        &__item {
            margin: 0px 5px;
            border: 1px #1976d2 solid;
            color: #1976d2;
            width: 150px;
            min-width: 150px;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
        }

        &__item.selected {
            background: #1976d2;
            color: white;
        }

        &__data {
            display: flex;
            flex-direction: row;
            min-width: 810px;
        }
    }
}

#schedule-sprint {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.table-header {
    &__column {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__button {
        margin: 0 !important;
        padding: 0 !important;
        min-width: 25px !important;
        margin-left: 5px !important;
        max-height: 25px !important;
    }
}

.table-header-button {
    margin: 0;
    padding: 0;
}

.table-column {
    padding: 2px 10px !important;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.functional-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}