.card {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-radius: 10px;
    background-color: #0d6efd;
    margin: 10px 0px 0px 10px;
    position: relative;
    color: white;
    padding: 10px;

    a {
        text-decoration: none;
        color: white;
        margin-right: 5px;
        cursor: pointer;
    }

    &__title {
        margin: 0;
    }

    &__header-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__left-side {
            display: flex;
            flex-direction: row;
        }
    }

    &__status {
        width: 100%;
        border-radius: 10px;
        padding: 0px 5px;
        color: white;
    }

    &__status.completed {
        background: #008000;
    }

    &__status.supposedly-completed {
        background: #F2BF57;
    }

    &__status.in-progress {
        background: #FF6600;
    }

}